import React from "react";

import styles from "./styles.module.less";

const Button = ({ label, onClick, customStyle }) => (
    <button
        className={styles.LinkButton}
        type="button"
        onClick={onClick}
        style={customStyle}>
        {label}
    </button>
);

export default Button;
