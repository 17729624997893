import netlifyIdentity from "netlify-identity-widget";

/**
 * Utility functions to get the cudrrent user / role from Netlify identity
 */

/**
 * Get the complete Netlify Identtiy user object from storage
 */
export function getIdentityUser() {
    return netlifyIdentity.currentUser();
}

/**
 * Get a simple user object from Identity
 * @returns {{email: String, roles: String[], name: String}}
 */
export function getUser() {
    const {
        email,
        app_metadata: { roles = ["editor"] },
        user_metadata: { full_name: name },
    } = getIdentityUser();
    return {
        email,
        roles,
        name,
    };
}

/**
 * @returns {boolean}
 */
export function isAdmin() {
    /** @type {String[]} */
    const { roles } = getUser();
    return !!roles.includes("admin");
}

/**
 * @returns {boolean}
 */
export function isEditor() {
    /** @type {String[]} */
    const { roles } = getUser();
    return !!roles.includes("editor");
}

/**
 * A user may already be logged in.
 * If so, they will have a user object during the 'init' phase
 * If a user is not logged in, init wil lhave no user, but
 * login will
 *
 * This function watches both `init` and `login` events for a user.
 * Once a user is captured, the provided function is called with a user context
 * object containing a simple user and a boolean isAdmin
 *
 * @param {Function} func
 */
export function onUserContextCreated(func) {
    let fired = false;
    const handler = user => {
        if (!fired && user) {
            fired = true;
            func({
                user: getUser(),
                isAdmin: isAdmin(),
                isEditor: isEditor(),
            });
        }
    };
    netlifyIdentity.on("init", handler);
    netlifyIdentity.on("login", handler);
}

export function onUserContext(func) {
    const handler = user => {
        if (user) {
            func({
                user: getUser(),
                isAdmin: isAdmin(),
                isEditor: isEditor(),
            });
        }
    };
    netlifyIdentity.on("init", handler);
    netlifyIdentity.on("login", handler);
}

export function init() {
    return netlifyIdentity.init();
}

export function signup() {
    return netlifyIdentity.open("signup");
}

export function login() {
    return netlifyIdentity.open("login");
}

export function close() {
    netlifyIdentity.close();
}

export function logout() {
    netlifyIdentity.logout();
}
